import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogActions,
  Box,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Clear';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const useStyles = makeStyles({
  dialogActions: {
    justifyContent: 'center',
  },
});

const DownloadDialog = ({
  name,
  description,
  version,
  releaseDate,
  win32Url,
  win64Url,
  onClose,
}) => {
  const handleDownload = url => {
    window.location = url;
  };
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Download</DialogTitle>
      <Box position="absolute" right={0} top={0}>
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText component="div">
          {Array.isArray(description) && (
            <>
              <Typography variant="subtitle2">{name}</Typography>
              <ul>
                {description.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </>
          )}
          {!Array.isArray(description) && description}
        </DialogContentText>
        <DialogContentText>
          {`Latest version: ${version} released ${releaseDate}.`}
        </DialogContentText>
        <DialogContentText>
          Please select your download below.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {win32Url && (
          <Button
            onClick={() => handleDownload(win32Url)}
            color="primary"
            variant="contained"
          >
            <SaveAltIcon />
            Win 32
          </Button>
        )}
        {win64Url && (
          <Button
            onClick={() => handleDownload(win64Url)}
            color="primary"
            variant="contained"
          >
            <SaveAltIcon />
            Win 64
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;
