import React from 'react';
import {
  Typography,
  Container,
  TextField,
  Grid,
  Box,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useForm from 'utils/useForm';
import * as signInClient from 'utils/signIn';
import { useAsync } from 'react-async';
import * as validate from 'utils/validateField';
import LoginMigrated from './LoginMigrated';
import DocumentTitle from 'react-document-title';

const useStyles = makeStyles(theme => ({
  emailNotification: {
    color: theme.palette.warning,
    fontSize: '12px',
    lineHeight: 1,
  },
}));

function migrateAccount([{ account, passwordResetKey }]) {
  return signInClient.migrate({ account, passwordResetKey });
}
function validateMigrateForm({ email, password, confirmPassword }) {
  const errors = {
    email: validate.email(email),
    password: validate.password(password),
  };

  if (password !== confirmPassword)
    errors.confirmPassword = 'Password does not match!';

  return errors;
}

const MigrateLogin = ({ passwordResetKey, account }) => {
  const classes = useStyles();
  const { values, handleChange, handleSubmit, errors } = useForm(
    handleSignIn,
    validateMigrateForm,
    { ...account, confirmPassword: account.password }
  );

  const { error, isLoading, run, isPending, isFulfilled } = useAsync({
    deferFn: migrateAccount,
  });

  function handleSignIn() {
    run({
      account: { ...values, oldPassword: account.password },
      passwordResetKey,
    });
  }

  return (
    <>
      <DocumentTitle title={'Review Account Details • EngageCX Account'} />
      {isFulfilled && <LoginMigrated email={values.email} />}
      {!isFulfilled && (
        <Container maxWidth="sm">
          <Box mt={8} mb={4}>
            <Typography variant="h6" align="center">
              Review Account Details
            </Typography>
          </Box>
          <Box mt={4} mb={4}>
            <Typography variant="subtitle1" align="center">
              Let's take a moment to update your account details
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              justify="center"
            >
              <Grid item xs={12} sm={3}>
                <Typography>First Name</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  name="firstName"
                  value={values.firstName || ''}
                  onChange={handleChange}
                  fullWidth
                  required
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>Last Name</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  name="lastName"
                  value={values.lastName || ''}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>Email</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  name="email"
                  value={values.email || ''}
                  onChange={handleChange}
                  helperText={
                    errors.email ? (
                      errors.email
                    ) : (
                      <Typography className={classes.emailNotification}>
                        IMPORTANT: You will login using this email address
                        moving forward. This email address cannot be changed and
                        must be verified within 48 hours.
                      </Typography>
                    )
                  }
                  error={Boolean(errors.email)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>Password</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  name="password"
                  value={values.password || ''}
                  onChange={handleChange}
                  helperText={errors.password}
                  error={Boolean(errors.password)}
                  type="password"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>Confirm Password</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  name="confirmPassword"
                  value={values.confirmPassword || ''}
                  onChange={handleChange}
                  fullWidth
                  type="password"
                  helperText={errors.confirmPassword}
                  error={Boolean(errors.confirmPassword)}
                  required
                />
              </Grid>
            </Grid>
            <Box mt={4}>
              <Grid container alignItems="center" direction="column">
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    direction="column"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        disabled={isLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {isLoading ? 'Updating...' : 'Update'}
                      </Button>
                    </Grid>
                    {!isPending && error && (
                      <Grid item xs={12}>
                        <Typography color="error">{error.message}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Container>
      )}
    </>
  );
};

export default MigrateLogin;
