import React, { useState } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Moment from 'react-moment';
import { useAsync } from 'react-async';
import InstallProductDialog from './InstallProductDialog';
import InstallLicenseDialog from './InstallLicenseDialog';
import UninstallDialog from './UninstallDialog';
import ActivationDialog from './ActivationDialog';
import * as productKeysClient from 'utils/productKeysClient';

const getLicenseKey = ({ product: { id } }) => {
  return productKeysClient.getActivationKey(id);
};

const ProductKeyDialog = ({ onClose, product, handleProductChange }) => {
  const { data: resultKey, isPending, counter } = useAsync({
    promiseFn: getLicenseKey,
    product,
  });

  const [dialogType, setDialogType] = useState(null);

  const showInstallDialog = () => {
    if (product.type === 'LicensingServer') setDialogType('InstallProduct');
    else setDialogType('InstallLicense');
  };

  const showUninstallDialog = () => {
    setDialogType('Uninstall');
  };

  const showActivationDialog = () => {
    setDialogType('Activation');
  };

  const handleDialogClose = () => setDialogType(null);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open
        onClose={onClose}
        disableBackdropClick
        disableRestoreFocus
      >
        <Grid container>
          <Grid item xs>
            <DialogTitle>Product Key {product.id}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Box mr={4} ml={4}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1">Product</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">
                      {product.feature}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1">Date Issued</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">
                      <Moment format="LL">{product.dateIssued}</Moment>
                    </Typography>
                  </TableCell>
                </TableRow>
                {!product.isTemporary && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        Maintainance/Updates Expiration Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <Moment format="LL">
                          {product.maintenanceExpirationDate}
                        </Moment>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {product.isTemporary && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        Expiration Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {product.updatesExpirationDate && (
                          <Moment format="LL">
                            {product.updatesExpirationDate}
                          </Moment>
                        )}
                        {!product.updatesExpirationDate && '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <Typography variant="subtitle1">Registration</Typography>
                  </TableCell>
                  <TableCell style={{ border: 'none' }}>
                    {product.usedOn && product.registrationDate && (
                      <Typography variant="subtitle1">
                        {product.usedOn + ' on '}
                        <Moment format="LL">{product.registrationDate}</Moment>
                      </Typography>
                    )}
                    {!product.usedOn && '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="flex-end">
            {!product.usedOn && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={showInstallDialog}
                >
                  {isPending && counter > 1 ? 'Installing...' : 'Install'}
                </Button>
              </Grid>
            )}
            {product.usedOn && (
              <Grid item>
                <Button
                  color="default"
                  variant="outlined"
                  onClick={showUninstallDialog}
                >
                  {isPending && counter > 1 ? 'Uninstalling...' : 'Uninstall'}
                </Button>
              </Grid>
            )}
            {product.type === 'LicensingServer' && product.usedOn && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={showActivationDialog}
                  disabled={isPending}
                >
                  Activation Key
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
      {dialogType === 'InstallProduct' && (
        <InstallProductDialog
          open
          onClose={handleDialogClose}
          handleProductChange={handleProductChange}
          id={product.id}
          type={product.type}
        />
      )}
      {dialogType === 'InstallLicense' && (
        <InstallLicenseDialog
          open
          onClose={handleDialogClose}
          handleProductChange={handleProductChange}
          id={product.id}
        />
      )}
      {dialogType === 'Uninstall' && (
        <UninstallDialog
          open
          onClose={handleDialogClose}
          handleProductChange={handleProductChange}
          id={product.id}
          type={product.type}
        />
      )}
      {dialogType === 'Activation' && (
        <ActivationDialog
          open
          onClose={handleDialogClose}
          activationKey={resultKey}
          id={product.id}
        />
      )}
    </>
  );
};

export default ProductKeyDialog;
