import React from 'react';
import { useAsync } from 'react-async';
import * as resetPasswordClient from 'utils/resetPasswordClient';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import Success from './Success';
import useForm from 'utils/useForm';

function requestChange([resetPassword]) {
  return resetPasswordClient.requestChange(resetPassword);
}

function validateForm(values) {
  const errors = {};

  const passwordRegexp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])/;

  if (values.password.length < 6) {
    errors.password = 'Minimum length is 6 characters.';
  } else if (!passwordRegexp.test(values.password)) {
    errors.password =
      'The password must be a combination of at least 3 of the following: an uppercase letter, a lowercase letter, a digit and a special character!';
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match!';
  }
  return errors;
}

const ChoosePassword = props => {
  const { resetKey } = props;
  const { values, handleChange, handleSubmit, errors } = useForm(
    handleFormSubmit,
    validateForm
  );

  const { error, run, isFulfilled, isLoading, isSettled, counter } = useAsync({
    deferFn: requestChange,
  });

  function handleFormSubmit() {
    run({ password: values.password, resetKey });
  }

  return (
    <>
      {isFulfilled && <Success passwordChanged />}
      {!isFulfilled && (
        <Container maxWidth="sm">
          <Box mt={8} mb={4}>
            <Typography variant="h6" align="center">
              Change your password
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography>Password</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  onChange={handleChange}
                  fullWidth
                  type="password"
                  name="password"
                  value={values.password || ''}
                  helperText={errors.password}
                  error={Boolean(errors.password)}
                  required
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>Confirm Password</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  value={values.confirmPassword || ''}
                  onChange={handleChange}
                  fullWidth
                  name="confirmPassword"
                  type="password"
                  helperText={errors.confirmPassword}
                  error={Boolean(errors.confirmPassword)}
                  required
                />
              </Grid>
              <Grid item align="center" xs={12}>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {isLoading && counter > 1 ? 'Saving...' : 'Save'}
                </Button>
              </Grid>
              {isSettled && error && (
                <Grid item xs={12}>
                  <Typography color="error" align="center">
                    {error.message}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </form>
        </Container>
      )}
    </>
  );
};
export default ChoosePassword;
