import React from 'react';
import { Box, CircularProgress, Fade } from '@material-ui/core';

const FadeInProgress = ({ isPending, size = 40 }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Fade
        in={isPending}
        style={{
          transitionDelay: isPending ? '1000ms' : '0ms',
          visibility: isPending ? 'visible' : 'hidden',
        }}
      >
        <CircularProgress size={size} />
      </Fade>
    </Box>
  );
};

export default FadeInProgress;
