import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Moment from 'react-moment';
import ProductKeyDialog from './ProductKeyDialog';
import * as productKeysClient from 'utils/productKeysClient';
import Warning from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  keyId: {
    width: '10%',
  },
  productName: {
    width: '35%',
    wordWrap: 'break-word',
  },
  expirationDate: {
    width: '14%',
  },
  usedOn: {
    width: '41%',
    wordWrap: 'break-word',
  },
  date: {
    minWidth: 64,
    '@media (min-width: 960px)': { minWidth: 250 },
  },
  warningSign: {
    position: 'absolute',
    paddingLeft: 10,
    margin: -5,
  },
  table: {
    width: '100%',
  }
}));

const updateProduct = id => {
  return productKeysClient.updateProductKey(id);
};

const LicensesTable = props => {
  const classes = useStyles();

  const [products, setProducts] = useState(props.products);
  const [openKey, setOpenKey] = useState(null);

  const openDialog = event => {
    event.preventDefault();
    const id = Number(event.target.firstChild.nodeValue);
    const product = products.find(a => {
      return a.id === id;
    });
    setOpenKey(product);
  };
  const closeDialog = () => setOpenKey(null);

  const handleProductChange = () => {
    updateProduct(openKey.id).then(response => {
      const updatedProducts = products.map(product => {
        return product.id === response.id ? response : product;
      });
      setOpenKey(response);
      setProducts(updatedProducts);
    });
  };

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.keyId}>
              <Typography variant="subtitle2">{'Key Id'}</Typography>
            </TableCell>
            <TableCell className={classes.productName}>
              <Typography variant="subtitle2">{'Product/Feature'}</Typography>
            </TableCell>
            <TableCell className={classes.expirationDate}>
              <Typography variant="subtitle2">
                {!products[0].isTemporary ? 'Updates Expire' : 'Valid Until'}
              </Typography>
            </TableCell>
            <TableCell className={classes.usedOn}>
              <Typography variant="subtitle2">{'Used On'}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(product => (
            <TableRow key={product.id}>
              <TableCell className={classes.keyId}>
                <Link onClick={openDialog} href="#">
                  {product.id}
                </Link>
              </TableCell>
              <TableCell className={classes.productName}>
                <Typography>{product.feature}</Typography>
              </TableCell>
              <TableCell className={classes.expirationDate}>
                <Typography className={classes.date}>
                  <Moment format="LL">
                    {!!product.updatesExpirationDate
                      ? product.updatesExpirationDate
                      : product.maintenanceExpirationDate}
                  </Moment>
                  <Typography
                    component={'span'}
                    className={classes.warningSign}
                  >
                    {new Date() >
                      new Date(
                        product.updatesExpirationDate
                          ? product.updatesExpirationDate
                          : product.maintenanceExpirationDate
                      ) &&
                      (!product.isTemporary ? (
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Free Software Updates Expired
                              </Typography>
                              You can no longer update to the latest version of
                              the software. The free software updates program
                              will become unavailable one year after the issue
                              date, or one year after the last renewal date. To
                              continue to have access to the latest version,
                              please renew your Premium Technical Support.
                            </React.Fragment>
                          }
                        >
                          <Warning color="error" />
                        </Tooltip>
                      ) : (
                          <Tooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  License Expired
                              </Typography>
                                Cannot use the software anymore. Contact Ecrion
                                support to get a license.
                            </React.Fragment>
                            }
                          >
                            <Warning color="error" />
                          </Tooltip>
                        ))}
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell className={classes.usedOn}>
                <Typography>{product.usedOn}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {openKey && (
        <ProductKeyDialog
          handleProductChange={handleProductChange}
          product={openKey}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

export default LicensesTable;
