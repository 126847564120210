import React, { useEffect } from 'react';
import { Container, Box, Typography, Button, Grid } from '@material-ui/core';
import { useAsync } from 'react-async';
import * as activateAccountClient from 'utils/activateAccountClient';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'context/AuthContext';

const resendActivationEmail = ([email]) => {
  return activateAccountClient.sendActivation(email);
};

const useStyles = makeStyles(theme => ({
  successMessage: {
    color: theme.palette.success,
  },
}));

const EmailPendingVerification = ({ history, autoResend }) => {
  const {
    data: {
      profile: { email },
    },
  } = useAuth();

  const { run, isLoading, isFulfilled, error } = useAsync({
    deferFn: resendActivationEmail,
  });

  const classes = useStyles();

  useEffect(() => {
    if (autoResend) run(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleResend() {
    run(email);
  }

  return (
    <Container maxWidth="md">
      <Box mt={8} mb={4}>
        <Typography variant="h6" align="center">
          {'Verify Email'}
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Typography variant="subtitle1" align="center">
              Please check <strong>'{email}'</strong> email for the verification
              message.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              {`Didn't get the mail?`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading}
              onClick={handleResend}
              variant="contained"
              color="primary"
            >
              {isLoading ? 'Sending Email...' : 'Resend Email'}
            </Button>
          </Grid>
          {!isLoading && error && (
            <Grid item xs={12}>
              <Typography color="error" align="center">
                {error.message}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mt={1} display="flex" justifyContent="center">
              {isFulfilled && (
                <Typography align="center" className={classes.successMessage}>
                  The email has been sent successfully!
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EmailPendingVerification;
