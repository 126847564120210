import React, { useEffect } from 'react';
import RequestReset from './RequestReset';
import ChoosePassword from './ChoosePassword';
import queryString from 'query-string';
import { useAsync } from 'react-async';
import * as resetPasswordClient from 'utils/resetPasswordClient';
import MigrateLogin from 'components/SignIn/MigrateLogin';
import PageProgress from 'components/PageProgress';
import { Typography, Box } from '@material-ui/core';

function validateKey([key]) {
  return resetPasswordClient.validateKey(key);
}

const PasswordRecovery = props => {
  const { key } = queryString.parse(props.location.search);
  const { error, run, data: account, isLoading } = useAsync({
    deferFn: validateKey,
  });

  useEffect(() => {
    if (key) run(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!key && <RequestReset />}
      {isLoading && <PageProgress />}
      {error && (
        <>
          <Box mt={8} mb={4}>
            <Typography variant="h6" align="center">
              {'Password recovery error'}
            </Typography>
          </Box>
          <Box mt={4} mb={4}>
            <Typography variant="subtitle1" align="center" color="error">
              {error.message}
            </Typography>
          </Box>
        </>
      )}
      {account && account.isMigratedToSSO && <ChoosePassword resetKey={key} />}
      {account && !account.isMigratedToSSO && (
        <MigrateLogin passwordResetKey={key} account={account} />
      )}
    </>
  );
};

export default PasswordRecovery;
