import React from 'react';
import {
  Grid,
  Typography,
  Avatar,
  Link,
  Box,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BusinessIcon from '@material-ui/icons/Business';
// import MicroserviceTile from './MicroserviceTile';

const useStyles = makeStyles(theme => ({
  root: {
    height: 72,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  avatar: {
    width: 40,
    height: 40,
    margin: '0px 4px',
  },
  customLogo: {
    width: 52,
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 140,
  },
}));

const ProductTile = ({ label, href, logo, microservices }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item>
        <Tooltip title={label}>
          <Link href={href} underline="none" target="_blank">
            <Grid
              container
              alignItems="center"
              justify="center"
              wrap="nowrap"
              spacing={1}
              className={classes.root}
            >
              <Grid item>
                <Box width={52}>
                  {!logo && (
                    <Avatar alt="Company Logo" className={classes.avatar}>
                      <BusinessIcon />
                    </Avatar>
                  )}
                  {logo && (
                    <img
                      className={classes.customLogo}
                      src={logo}
                      alt={'Environment Icon'}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item className={classes.label}>
                <Typography noWrap>{label}</Typography>
              </Grid>
            </Grid>
          </Link>
        </Tooltip>
      </Grid>
      {/* <Grid item>
        <Grid container spacing={1}>
          {microservices &&
            microservices.map((microservice, index) => (
              <Grid item key={index}>
                <MicroserviceTile
                  label={microservice.Name}
                  href={microservice.Url}
                  logo={microservice.Logo}
                  status={microservice.InstalationStatus}
                />
              </Grid>
            ))}
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default ProductTile;
