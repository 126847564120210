export const email = value => {
  const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!emailRegexp.test(value)) return 'Please enter a valid email address!';
};

export const password = value => {
  const passwordRegexp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])/;

  if (value.length < 6) {
    return 'Minimum length is 6 characters.';
  } else if (!passwordRegexp.test(value)) {
    return 'The password must be a combination of at least 3 of the following: an uppercase letter, a lowercase letter, a digit and a special character!';
  }
};
