import React from 'react';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import { useAuth } from 'context/AuthContext';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import RedirectCounter from 'components/RedirectCounter';

const SignupSuccess = ({ email, location }) => {
  const { signIn } = useAuth();

  const handleSignIn = () => {
    const query = queryString.parse(location.search);
    const signInOptions = { email: email };
    if (query['redirectUrl']) window.location = query['redirectUrl'];
    else signIn(signInOptions);
  };

  return (
    <Container maxWidth="lg">
      <Box mt={8} mb={4}>
        <Typography variant="h6" align="center">
          Account Created!
        </Typography>
      </Box>

      <Box mt={4} mb={4}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" align="center">
              Your account has been successfully created!
            </Typography>
          </Grid>
          <Grid item>
            <RedirectCounter handleSignIn={handleSignIn} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withRouter(SignupSuccess);
