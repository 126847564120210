import client from './apiClient';

function checkToken() {
  return client('token');
}

function deleteToken() {
  return client('token', { method: 'DELETE' });
}

export { deleteToken, checkToken };
