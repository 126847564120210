import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useAsync } from 'react-async';
import * as productKeysClient from 'utils/productKeysClient';
import ActivationDialog from './ActivationDialog';

const installLicenseKey = ([licenseKey]) => {
  return productKeysClient.installLicense(licenseKey);
};

const InstallProductDialog = ({
  onClose,
  open,
  id,
  type,
  handleProductChange,
}) => {
  const [showActivationDialog, toggleActivationDialog] = useState(false);
  const [keys, setKeys] = useState({
    activationKey: '',
    responseKey: '',
  });

  const { run, error, isPending, counter } = useAsync({
    deferFn: installLicenseKey,
  });

  const handleActivationKey = ({ target: { value } }) => {
    setKeys({ ...keys, activationKey: value });
  };

  const installLicense = event => {
    event.preventDefault();
    run({ id, activationKey: keys.activationKey, type }).then(response => {
      if (!isPending)
        if (typeof response === 'string') {
          toggleActivationDialog(true);
          setKeys({ ...keys, responseKey: response });
          handleProductChange();
        }
    });
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open && !showActivationDialog}
        onClose={onClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <form onSubmit={installLicense}>
          <Grid container>
            <Grid item xs>
              <DialogTitle>Install Product Key</DialogTitle>
            </Grid>
            <Grid item>
              <IconButton color="inherit" onClick={onClose} aria-label="Close">
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {
                    'You should paste the activation key received from the environment where you want to install selected product key.'
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{'Activation key'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleActivationKey}
                  value={keys.activationKey}
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box width={1} display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" type="submit">
                {isPending && counter > 1 ? 'Installing...' : 'Install'}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      {keys.responseKey && (
        <ActivationDialog
          open={showActivationDialog}
          onClose={onClose}
          activationKey={keys.responseKey}
        />
      )}
    </>
  );
};

export default InstallProductDialog;
