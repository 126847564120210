import React from 'react';
import { Container, Grid, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NotFound from './NotFound.svg';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justify="center"
          wrap="nowrap"
          direction="column"
        >
          <Grid item>
            <Box height={300} width="auto" clone>
              <img src={NotFound} alt="" />
            </Box>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2} direction="column">
              <Grid item>
                <Typography variant="h4">{'404 - Page Not Found'}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {"We're sorry, we couldn't find the page you requested."}
                </Typography>
              </Grid>
              <Grid item>
                <Button href="/" color="primary" variant="contained">
                  Go to home page
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PageNotFound;
