import React, { useState, useEffect } from 'react';
import Auth, { parseUrl } from 'utils/auth';
import * as tokenClient from 'utils/tokenClient';
import { useSettings } from './SettingsContext';

const AuthContext = React.createContext();
let expiresAt = 0;

const getRedirectUrl = () => {
  var urlRoot =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');

  return `${urlRoot}/auth-callback`;
};

function AuthProvider(props) {
  const [profile, setProfile] = React.useState(null);
  const [token, setToken] = React.useState('');
  const [isInitialState, setIsInitialState] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isSettled, setIsSettled] = useState(false);

  const [auth, setAuth] = useState(null);
  const settings = useSettings();

  useEffect(() => {
    if (settings) {
      setAuth(
        new Auth({
          loginUrl: settings.idPLoginUrl,
          redirectUri: getRedirectUrl(),
        })
      );
    }
  }, [settings]);

  const setSession = authResult => {
    window.localStorage.setItem('_token_', authResult.accessToken);

    setToken(authResult.accessToken);
    setProfile({ ...authResult.account });
    // javascript timestamp is in milliseconds
    expiresAt = authResult.expiresAt * 1000;
  };

  const isAuthenticated = () => {
    return new Date().getTime() < expiresAt;
  };

  const checkSession = () => {
    setIsPending(true);
    setIsInitialState(false);

    tokenClient
      .checkToken()
      .then(payload => {
        setSession(payload);
        setIsPending(false);
        setIsSettled(true);
      })
      .catch(() => setIsPending(false));
  };

  const handleAuthentication = () => {
    setSession(parseUrl());
  };

  const signIn = ({ email, redirectUrl } = {}) => {
    if (!auth) return;

    auth.authorize({
      ssoParams: { email },
      redirectParams: { redirectUrl },
    });
  };

  const signOut = () => {
    tokenClient.deleteToken().then(payload => {
      window.localStorage.removeItem('_token_');
      window.location = payload.redirectUrl;
    });
  };

  const settleForAnonym = () => {
    setIsSettled(true);
  };

  return (
    <AuthContext.Provider
      value={{
        data: {
          token,
          profile,
        },
        isAuthenticated,
        isInitialState,
        isPending,
        isSettled,
        checkSession,
        handleAuthentication,
        settleForAnonym,
        signIn,
        signOut,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined)
    throw new Error('useAuth must be used within a AuthProvider');

  return context;
}

export { AuthProvider, useAuth };
