import React from 'react';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import RedirectCounter from 'components/RedirectCounter';

const VerifyResult = ({ error, history }) => {
  const handleSignIn = () => {
    history.push('/');
  };

  return (
    <Container maxWidth="md">
      <Box mt={8} mb={4}>
        <Typography variant="h6" align="center">
          {error ? 'Email Verification Error' : 'Email Verified'}
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        {error && (
          <Typography variant="subtitle1" color="error" align="center">
            {error}
          </Typography>
        )}
        {!error && (
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" align="center">
                Your email has been successfully verified!
              </Typography>
            </Grid>
            <Grid item>
              <RedirectCounter handleSignIn={handleSignIn} />
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default VerifyResult;
