import React from 'react';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import { useAuth } from 'context/AuthContext';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import RedirectCounter from 'components/RedirectCounter';

const LoginMigrated = ({ email, location }) => {
  const { signIn } = useAuth();

  function handleSignIn() {
    const query = queryString.parse(location.search);

    signIn({ email: email, redirectUrl: query['redirectUrl'] });
  }

  return (
    <Container maxWidth="md">
      <Box mt={8} mb={4}>
        <Typography variant="h6" align="center">
          Account Updated!
        </Typography>
      </Box>

      <Box mt={4} mb={4}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" align="center">
              Account details updated successfully!
            </Typography>
          </Grid>
          <Grid item>
            <RedirectCounter handleSignIn={handleSignIn} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withRouter(LoginMigrated);
