import React from 'react';
import {
  IconButton,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

const ActivationDialog = ({ onClose, open, activationKey = '' }) => {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableRestoreFocus
      disableBackdropClick
    >
      <Grid container>
        <Grid item xs>
          {activationKey && <DialogTitle>Activate Product Key</DialogTitle>}
          {!activationKey && <DialogTitle>Uninstall Product Key</DialogTitle>}
        </Grid>
        <Grid item>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <Close fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {!activationKey && (
          <Box width={1} display="flex" justifyContent="left">
            <Typography variant="body1">
              {'The license has been succesfully uninstalled.'}
            </Typography>
          </Box>
        )}
        {activationKey && (
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {
                  'The product key has been activated. Go to the environment where the product key is to be installed and use the activation key below to finalize the installation.'
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{'Activation key'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                multiline
                rows={6}
                value={activationKey}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Box width={1} display="flex" justifyContent="flex-end">
          <Button color="default" variant="outlined" onClick={onClose}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ActivationDialog;
