import client from './apiClient';
let cache = null;
const LOCAL_STORAGE_KEY = '_apps_cache_';

function apps() {
  if (cache) return Promise.resolve(cache);

  const result = client('accountsapps');

  result.then(apps => {
    cache = apps;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(apps));
  });

  return result;
}

// environments request takes some time to complete
// use local storage to cache the retrieved items
const initialValue = (function() {
  const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY);
  return localStorageValue ? JSON.parse(localStorageValue) : null;
})();

function installApp(url, environmentName, appName) {
  const installValues = {
    Url: url,
    Environment: environmentName,
    App: appName,
  };
  const result = client('accountsapps', { body: installValues });
  return result;
}

export { apps, installApp, initialValue };
