import React, { useMemo, useState } from 'react';
import {
  Paper,
  Container,
  Box,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useAsync } from 'react-async';
import * as productKeysClient from 'utils/productKeysClient';
import LicensesTable from './LicensesTable';
import FadeInProgress from 'components/FadeInProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RestoreDialog from './RestoreDialog';

const getProducts = () => {
  return productKeysClient.productKeys();
};

const ProductKeys = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRestoreDialog, setRestoreDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRestoreSelect = () => {
    console.log('selected');
    setRestoreDialog(true);
    handleClose();
  };

  const closeDialog = () => setRestoreDialog(null);

  const { data: productKeys, isPending, error } = useAsync({
    promiseFn: getProducts,
  });

  const groupLicenses = () => {
    const licenses = {
      temporaryLicenses: [],
      accountsLicense: [],
      licensingServer: [],
    };

    if (productKeys) {
      for (let i = 0; i < productKeys.length; i++) {
        if (productKeys[i].type === 'LicensingServer') {
          licenses.licensingServer.push(productKeys[i]);
        } else if (
          productKeys[i].type === 'AccountsLicense' &&
          productKeys[i].isTemporary
        ) {
          licenses.temporaryLicenses.push(productKeys[i]);
        } else {
          licenses.accountsLicense.push(productKeys[i]);
        }
      }
    }
    return licenses;
  };

  const licenses = useMemo(groupLicenses, [productKeys]);

  if (isPending) return <FadeInProgress isPending={isPending} />;

  if (error)
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="subtitle2" color="error">
          {error.message}
        </Typography>
      </Box>
    );

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {productKeys.length === 0 && (
            <Grid item>
              <Typography variant="subtitle2" align="center">
                No product keys found
              </Typography>
            </Grid>
          )}
          {licenses.licensingServer.length > 0 && (
            <Grid item>
              <Box mb={2}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle2" >
                      Product Keys
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="More"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Grid>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleRestoreSelect}>Restore HA Product Keys</MenuItem>
                  </Menu>
                </Grid>
              </Box>
              <Paper>
                <LicensesTable products={licenses.licensingServer} />
              </Paper>
            </Grid>
          )}

          {licenses.accountsLicense.length > 0 && (
            <Grid item>
              <Box mb={3}>
                <Typography variant="subtitle2" align="left">
                  Purchased License Keys
                </Typography>
              </Box>
              <Paper>
                <LicensesTable products={licenses.accountsLicense} />
              </Paper>
            </Grid>
          )}

          {licenses.temporaryLicenses.length > 0 && (
            <Grid item>
              <Box mb={3}>
                <Typography variant="subtitle2" align="left">
                  Evaluation Keys
                </Typography>
              </Box>
              <Paper>
                <LicensesTable products={licenses.temporaryLicenses} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
      {openRestoreDialog && <RestoreDialog onClose={closeDialog} />}
    </>
  );
};

export default ProductKeys;
