import React from 'react';
import { Box, Grid, Typography, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const ProductTile = ({ icon, label, onClick }) => {
  const classes = useStyles();

  return (
    <ButtonBase onClick={onClick}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.root}
        wrap="nowrap"
      >
        <Grid item>
          <Box height={40} width="auto" clone>
            <img src={icon} alt={`${label} Icon`} />
          </Box>
        </Grid>
        <Grid item>
          <Typography align="center">{label}</Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default ProductTile;
