import React from 'react';
import { useAsync } from 'react-async';
import * as settingsClient from 'utils/settingsClient';

const SettingsContext = React.createContext();

function retrieveSettings() {
  return settingsClient.settings();
}

function SettingsProvider(props) {
  const { data: settings = null } = useAsync({
    promiseFn: retrieveSettings,
  });

  return <SettingsContext.Provider value={settings} {...props} />;
}

function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }
  return context;
}

export { SettingsProvider, useSettings };
