import React from 'react';
import { AuthProvider } from './AuthContext';
import { UserProvider } from './UserContext';
import { SettingsProvider } from './SettingsContext';

function AppProviders({ children }) {
  return (
    <SettingsProvider>
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    </SettingsProvider>
  );
}

export default AppProviders;
