import client from './apiClient';

function requestReset({ email, recaptchaResponse }) {
  return client(
    `resetpassword?email=${email}&recaptchaResponse=${recaptchaResponse}`,
    { method: 'PUT' }
  );
}

function requestChange({ password, resetKey }) {
  const resetPassword = { Key: resetKey, Password: password };
  return client(`resetpassword`, { body: resetPassword });
}

function validateKey(resetKey) {
  return client(`resetpassword/validate`, { body: resetKey });
}

export { requestReset, requestChange, validateKey };
