import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Error from './light.svg';
import Navbar from 'components/Navbar';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ErrorPage = ({
  title = 'Unexpected Error',
  message = 'Looks like something went wrong on our end. Head back to the home page.',
  details,
}) => {
  const classes = useStyles();
  const [isDetailsVisible, toggleDetailsVisibility] = useState(false);

  return (
    <>
      <Navbar />
      <Container maxWidth="md" className={classes.container}>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justify="center"
          wrap="nowrap"
          direction="column"
        >
          <Grid item>
            <Box height={300} width="auto" clone>
              <img src={Error} alt="" />
            </Box>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={2} direction="column">
              {!isDetailsVisible && (
                <>
                  <Grid item>
                    <Typography variant="h4">{title}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">{message}</Typography>
                  </Grid>
                  {details && (
                    <Grid item>
                      <Button
                        color="default"
                        onClick={() => toggleDetailsVisibility(v => !v)}
                      >
                        View Details
                        <ArrowDown />
                      </Button>
                    </Grid>
                  )}
                </>
              )}
              {isDetailsVisible && (
                <>
                  <Grid item>
                    <Button
                      color="default"
                      onClick={() => toggleDetailsVisibility(v => !v)}
                    >
                      Hide Details
                      <ArrowUp />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      {'Unexpected Error'}
                    </Typography>
                    <Typography variant="subtitle1">
                      {'Unable to load.'}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Button href="/" color="primary" variant="contained">
              Go to home page
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ErrorPage;
