import client from './apiClient';
let cache = null;

function products(account) {
  if (cache) return Promise.resolve(cache);

  const result = client('products');
  result.then(environment => (cache = environment));

  return result;
}

export { products };
