import React, { useState } from 'react';
import * as productsClient from 'utils/productsClient';
import { useAsync } from 'react-async';
import ProductTile from './ProductTile';

import { Grid, Typography } from '@material-ui/core';
import productsDef from './productsDef';
import DownloadDialog from './DownloadDialog';
import { useUser } from 'context/UserContext';

const getProducts = () => {
  return productsClient.products();
};

const DownloadProducts = () => {
  const { data: products = [] } = useAsync({
    promiseFn: getProducts,
  });

  const [productOpen, setProductOpen] = useState();

  const user = useUser();

  const handleProductClick = productDef => {
    const win32 =
      productDef.win32ConfigId &&
      products.find(p => p.Id === productDef.win32ConfigId);
    const win64 =
      productDef.win64ConfigId &&
      products.find(p => p.Id === productDef.win64ConfigId);

    if (!win32 && !win64) return;

    setProductOpen({
      ...productDef,
      version: win64.Version,
      releaseDate: win64.ReleaseDate,
      win32Url:
        win32 &&
        win32.Mirror + win32.DownloadFolder + win32.DownloadName + '.exe',
      win64Url:
        win64 &&
        win64.Mirror + win64.DownloadFolder + win64.DownloadName + '.exe',
    });
  };

  const handleClose = () => {
    setProductOpen(null);
  };

  return (
    <>
      <Grid container justify="center" alignItems="center" spacing={6}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="center">
                Downloads
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} justify="center" alignItems="center">
                {productsDef.downloads.map(product => {
                  if (user.isTrial && !product.availableInTrial) return null;
                  return (
                    <Grid item key={product.name}>
                      <ProductTile
                        icon={product.icon}
                        label={product.name}
                        onClick={() => handleProductClick(product)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!user.isTrial && (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" align="center">
                  Phased-out Products
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={4}
                  justify="center"
                  alignItems="center"
                >
                  {productsDef.phasedOutProducts.map(product => (
                    <Grid item key={product.name}>
                      <ProductTile
                        icon={product.icon}
                        label={product.name}
                        onClick={() => handleProductClick(product)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {productOpen && <DownloadDialog {...productOpen} onClose={handleClose} />}
    </>
  );
};

export default DownloadProducts;
