import React from 'react';
import { TextField, MenuItem, Grid, Typography } from '@material-ui/core';
import { useAsync } from 'react-async';
import * as cloudInstancesClient from 'utils/cloudInstancesClient';

function getCloudInstances() {
  return cloudInstancesClient.cloudInstances();
}

const CloudInstanceSelect = ({ value, onChange, name }) => {
  const { data: instances, error, isPending } = useAsync({
    promiseFn: getCloudInstances,
    onResolve: data => onChange({ target: { name, value: data[0].id } }),
  });

  if (!instances || instances.length <= 1) return null;

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12} sm={4}>
        <Typography>Region</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          fullWidth
          select={Boolean(instances)}
          error={error}
          helperText={error}
          value={value}
          disabled={isPending}
          onChange={onChange}
          name={name}
        >
          {instances &&
            instances.map(instance => (
              <MenuItem key={instance.id} value={instance.id}>
                {instance.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default CloudInstanceSelect;
