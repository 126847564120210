import { createMuiTheme } from '@material-ui/core/styles';

const textColorDark = 'rgba(0,0,0,0.87)';
// const textColorNeutral = '#6E8CA0';
const textColorLight = '#FFFFFF';
const textColorGray = '#798186';

// const primaryLight = "#87BCBF";
const primary = '#FF6633';
const secondary = '#383333';

// override default material ui theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: textColorLight,
    },
    secondary: {
      main: secondary,
      contrastText: textColorLight,
    },
    text: {
      primary: textColorDark,
    },
    success: '#3C763D',
    warning: '#D57704',
  },
  typography: {
    fontFamily: '"Lato", sans-serif',
    h4: {
      color: textColorDark,
      fontSize: '24px',
      fontWeight: 'bold',
    },
    h5: {
      color: textColorDark,
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: 1.45,
    },
    h6: {
      color: textColorDark,
      fontSize: '16px',
      fontWeight: 'bold',
    },
    subtitle1: {
      color: textColorDark,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.38,
    },
    subtitle2: {
      color: textColorDark,
      fontSize: '14px',
      fontWeight: 700,
    },
    body1: {
      color: textColorDark,
      fontSize: '14px',
    },
    body2: {
      color: textColorDark,
      fontSize: '12px',
    },
    caption: {
      color: textColorGray,
      fontSize: '12px',
    },
    overline: {
      color: textColorGray,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: 1,
      letterSpacing: '0.15em',
    },
    button: {
      color: textColorDark,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: '14px',
      letterSpacing: '0px',
      textTransform: 'none',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: 8,
        fontSize: 14,
      },
      multiline: {
        padding: 8,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    MuiTab: {
      root: {
        letterSpacing: '0px',
        fontWeight: 700,
      },
    },
    MuiButton: {
      root: {
        padding: '4px 16px',
        whiteSpace: 'nowrap',
      },
      outlined: {
        padding: '4px 16px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: 16,
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: '800px',
      },
    },
    MuiTable: {
      root: {
        tableLayout: 'fixed',
      },
    },
  },
});

export default theme;
