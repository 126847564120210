import React, { useRef } from 'react';
import { useAsync } from 'react-async';
import * as resetPasswordClient from 'utils/resetPasswordClient';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Reaptcha from 'reaptcha';
import Success from './Success';
import { useSettings } from 'context/SettingsContext';
import useForm from 'utils/useForm';

const useStyles = makeStyles(theme => ({
  recaptchaErrorMessage: {
    margin: '-12px 12px 0px',
  },
}));

function requestReset([data]) {
  return resetPasswordClient.requestReset(data);
}

function validateForm({ recaptcha = {}, email }) {
  const errors = {};

  if (!recaptcha.response)
    errors.recaptcha = 'Please confirm that you are not a robot!';

  const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  if (!regexp.test(email)) errors.email = 'Please enter a valid email!';

  return errors;
}

const RequestReset = props => {
  const classes = useStyles();
  const recaptchaInstance = useRef(null);

  const settings = useSettings();

  const { error, run, isFulfilled, isLoading, isSettled, counter } = useAsync({
    deferFn: requestReset,
    onReject: () => recaptchaInstance.current.reset(),
  });

  const { values, handleChange, handleSubmit, errors } = useForm(
    handleFormSubmit,
    validateForm
  );

  function handleFormSubmit(e) {
    run({ email: values.email, recaptchaResponse: values.recaptcha.response });
  }

  return (
    <>
      {isFulfilled && <Success email={values.email} />}
      {!isFulfilled && (
        <Container maxWidth="md">
          <Box mt={8} mb={4}>
            <Typography variant="h6" align="center">
              Forgot your password?
            </Typography>
          </Box>
          <Box mt={4} mb={4}>
            <Typography variant="subtitle1" align="center">
              To reset your password enter your email address. An email will be
              sent with password reset instructions.
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justify="center"
              >
                <Grid item xs={12} sm={3}>
                  <Typography>Email</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    placeholder="Enter email..."
                    fullWidth
                    name="email"
                    value={values.email || ''}
                    onChange={handleChange}
                    helperText={errors.email}
                    error={Boolean(errors.email)}
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={12} sm={9}>
                  {settings && (
                    <Reaptcha
                      ref={recaptchaInstance}
                      sitekey={settings.recaptchaSiteKey}
                      onVerify={response => {
                        handleChange({
                          target: { name: 'recaptcha', value: { response } },
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={12} sm={9}>
                  <Typography
                    className={classes.recaptchaErrorMessage}
                    variant="caption"
                    color="error"
                  >
                    {errors.recaptcha}
                  </Typography>
                </Grid>
                <Grid item align="center" xs={12}>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isLoading && counter > 1 ? 'Submitting...' : 'Submit'}
                  </Button>
                </Grid>
                {isSettled && error && (
                  <Grid item xs={12}>
                    <Typography color="error" align="center">
                      {error.message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Container>
        </Container>
      )}
    </>
  );
};

export default RequestReset;
