import React from 'react';
import studioIcon from 'assets/EcrionStudioIcon.svg';
import developIcon from 'assets/EcrionDevelopIcon.svg';
import engageIcon from 'assets/EcrionEngageIcon.svg';
import ecrionIcon from 'assets/IconEcrion.png';
import edsIcon from 'assets/Eds64x64.png';

const productsDef = {
  downloads: [
    {
      name: 'Ecrion Studio',
      icon: studioIcon,
      description: [
        'Create document templates for high-volume document production.',
        'Visual and intuitive for business users and developers alike.',
        'Design Studio Online extends your desktop experience to the web browser.',
      ],
      win32ConfigId: '41',
      win64ConfigId: '42',
      availableInTrial: true,
    },
    {
      name: 'Ecrion Develop',
      icon: developIcon,
      description: (
        <>
          <b>Ecrion Develop</b> provides software developers with complex
          document production features ready to be leveraged in custom built
          solutions.
        </>
      ),
      win32ConfigId: '35',
      win64ConfigId: '36',
    },
    {
      name: 'Ecrion Engage',
      icon: engageIcon,
      description: (
        <>
          <b>Ecrion Engage</b> provides decision makers with insight into
          customer journeys and tools for developing profitable relationships.
        </>
      ),
      // win32ConfigId: '39',
      win64ConfigId: '40',
    },
  ],
  phasedOutProducts: [
    {
      name: 'EOS-P',
      icon: ecrionIcon,
      description: [
        'High volume document production from XML and JSON',
        '100% dynamic documents with complex layout, features and typesetting support.',
        'Documents can be delivered on web and mobile devices with superb drill-down and interactive features.',
      ],
      win32ConfigId: '33',
      win64ConfigId: '34',
    },
    {
      name: 'Floating Licenses Manager',
      icon: ecrionIcon,
      description: [
        'Allow multiple employees and contractors to share Ecrion Designer Studio floating licenses.',
        'Deploy licenses on a single server in your organization.',
      ],
      win32ConfigId: '31',
      win64ConfigId: '32',
    },
    {
      name: 'Ecrion Design Studio',
      icon: edsIcon,
      description: [
        'Create document templates for high-volume document production.',
        'Visual and intuitive for business users and developers alike.',
        'Design Studio Online extends your desktop experience to the web browser.',
      ],
      win32ConfigId: '19',
      win64ConfigId: '20',
    },
  ],
};

export default productsDef;
