import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from 'logo.svg';
import { AppBar, Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useUser } from 'context/UserContext';
import User from './User';

const useStyles = makeStyles(theme => ({
  logo: {
    height: 34,
    verticalAlign: 'middle',
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const user = useUser();

  return (
    <AppBar position="static" elevation={0} color="secondary">
      <Container>
        <Box height={{ xs: 60, md: 80 }} clone>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <a
                href="http://accounts.ecrion.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo} alt="MHC Logo" className={classes.logo} />
              </a>
            </Grid>
            <Grid item xs align="center">
              <Typography
                component={RouterLink}
                to="/"
                variant="h6"
                color="inherit"
                style={{ textDecoration: 'none' }}
              >
                EngageCX Account
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {user && (
                <Grid
                  container
                  justify="flex-end"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Grid item>
                    <User />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Navbar;
