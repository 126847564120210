import React from 'react';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import { useAuth } from 'context/AuthContext';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import RedirectCounter from 'components/RedirectCounter';

const Success = ({ email, location, passwordChanged }) => {
  const { signIn } = useAuth();
  const mailSent = Boolean(email);

  const handleSignIn = () => {
    const query = queryString.parse(location.search);
    signIn({ email: email, redirectUrl: query['redirectUrl'] });
  };

  return (
    <>
      <Container maxWidth="md">
        {mailSent && (
          <>
            <Box mt={8} mb={4}>
              <Typography variant="h6" align="center">
                Password reset email sent!
              </Typography>
            </Box>
            <Box mt={4} mb={4}>
              <Typography variant="subtitle1" align="center">
                An email has been sent to <strong>'{email}'</strong> with your
                password reset link.
              </Typography>
            </Box>
          </>
        )}
        {passwordChanged && (
          <>
            <Box mt={4} mb={4}>
              <Typography variant="h6" align="center">
                Password Changed!
              </Typography>
            </Box>
            <Box mt={4} mb={4}>
              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="subtitle1" align="center">
                    Your password has been succesfully changed!
                  </Typography>
                </Grid>
                <Grid item>
                  <RedirectCounter handleSignIn={handleSignIn} />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default withRouter(Success);
