import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import * as productKeysClient from 'utils/productKeysClient';
import { useAsync } from 'react-async';
import ActivationDialog from './ActivationDialog';

const uninstallProduct = ([uninstallData]) => {
  return productKeysClient.uninstallLicense(uninstallData);
};

const UninstallDialog = ({ onClose, open, id, type, handleProductChange }) => {
  const [uninstallKey, setUninstallKey] = useState('');
  const [showActivationDialog, toggleActivationDialog] = useState(false);

  const { run, error, isPending } = useAsync({
    deferFn: uninstallProduct,
  });

  const handleUninstallKey = ({ target: { value } }) => {
    setUninstallKey(value);
  };

  const uninstallProductKey = event => {
    event.preventDefault();
    run({ id, type, uninstallKey }).then(response => {
      if (!isPending)
        if (!response.message) {
          toggleActivationDialog(true);
          handleProductChange();
        }
    });
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open && !showActivationDialog}
        onClose={onClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <form onSubmit={uninstallProductKey}>
          <Grid container>
            <Grid item xs>
              <DialogTitle>Uninstall Product Key</DialogTitle>
            </Grid>
            <Grid item>
              <IconButton color="inherit" onClick={onClose} aria-label="Close">
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                {type === 'LicensingServer' && (
                  <Typography variant="body1">
                    {
                      'You should paste the uninstall key received from the environment where you want to uninstall selected product key.'
                    }
                  </Typography>
                )}
                {type === 'AccountsLicense' && (
                  <Typography variant="body1">
                    {
                      'Go to the computer where the license is installed, open License Administrator, select the license and click Uninstall. When prompted, choose Uninstall License Offline.You will receive an uninstall key. Paste it in the box below.'
                    }
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{'Uninstall key'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleUninstallKey}
                  value={uninstallKey}
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box width={1} display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" type="submit">
                {!isPending && 'Uninstall'}
                {isPending && 'Uninstalling...'}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      {showActivationDialog && (
        <ActivationDialog open={showActivationDialog} onClose={onClose} />
      )}
    </>
  );
};

export default UninstallDialog;
