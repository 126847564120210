import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  OutlinedInput,
} from '@material-ui/core';
import ChangePasswordDialog from './ChangePasswordDialog';
import { makeStyles } from '@material-ui/core/styles';
import { useAsync } from 'react-async';
import { withRouter, Link } from 'react-router-dom';
import * as accountsClient from 'utils/accountsClient';
import * as changePasswordClient from 'utils/changePasswordClient';
import useForm from 'utils/useForm';
import LicensePassword from './LicensePassword';

const useStyles = makeStyles(theme => ({
  successMessage: {
    color: theme.palette.success,
  },
  verifyText: {
    color: theme.palette.text.primary,
  },
  verifyButton: {
    color: theme.palette.warning,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
}));

function getAccount() {
  return accountsClient.getAccount();
}

function updateAccount([account]) {
  return accountsClient.updateAccount(account);
}

function handlePasswordChange([password]) {
  return changePasswordClient.changePassword(password);
}

function handleLicensePasswordChange([password]) {
  return changePasswordClient.changeLicensePassword(password);
}

function validateUserProfileForm({ companyName }) {
  const errors = {};

  if (companyName) {
    if (companyName.length < 3)
      errors.companyName =
        'The Company Name must be of minimum 3 characters length.';
  }

  return errors;
}

const UserProfile = ({ history }) => {
  const classes = useStyles();

  const { values, handleChange, handleSubmit, errors, fillValues } = useForm(
    handleSubmitProfile,
    validateUserProfileForm
  );

  const [modal, setModalState] = useState({
    password: false,
    licensePassword: false,
  });

  const {
    error,
    isPending,
    isRejected,
    isFulfilled,
    counter,
    run,
    reload,
  } = useAsync({
    promiseFn: getAccount,
    deferFn: updateAccount,
    onResolve: data => fillValues(data),
  });

  const checkTitle = () => {
    const titles = [
      'Manager',
      'Business Analyst',
      'Software Developer',
      'Software Architect',
    ];
    return titles.find(title => title === values.title);
  };

  const otherTitle = () => {
    if (values.title === 'Other' || !values.title) return '';
    else return values.title;
  };

  function handleSubmitProfile() {
    run(values);
  }

  const handlePasswordDialogOpen = event => {
    event.preventDefault();
    setModalState({ password: true });
  };
  const handleLicensePasswordDialogOpen = event => {
    event.preventDefault();
    setModalState({ licensePassword: true });
  };
  const handleDialogClose = () =>
    setModalState({ password: false, licensePassword: false });

  return (
    <>
      <Container maxWidth="sm">
        <Box mt={8} mb={4}>
          <Typography variant="h6" align="center">
            Edit Profile
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-start" justify="center">
            <Grid item xs={12} sm={3}>
              <Typography>First Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                autoFocus
                fullWidth
                required
                value={values.firstName || ''}
                name="firstName"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>Last Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                required
                name="lastName"
                value={values.lastName || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>Phone(Optional)</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                name="phone"
                value={values.phone || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>Email</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                disabled
                name="email"
                value={values.email || ''}
                onChange={handleChange}
              />
              {values.expirationDate !== null && (!isPending || counter > 1) && (
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography
                      variant="caption"
                      className={classes.verifyText}
                    >
                      Email not verified.{' '}
                      <Link
                        className={classes.verifyButton}
                        to="/verify-email/resend"
                      >
                        Verify Now
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>Company(Optional)</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                value={values.companyName || ''}
                onChange={handleChange}
                name="companyName"
                helperText={errors.companyName}
                error={Boolean(errors.companyName)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography>Title(Optional)</Typography>
            </Grid>
            <Grid item xs={6} sm={5}>
              <Select
                name="title"
                value={checkTitle() ? values.title : 'Other'}
                onChange={handleChange}
                fullWidth
                input={<OutlinedInput />}
              >
                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="Business Analyst">Business Analyst</MenuItem>
                <MenuItem value="Software Developer">
                  Software Developer
                </MenuItem>
                <MenuItem value="Software Architect">
                  Software Architect
                </MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} sm={4}>
              {!checkTitle() && (
                <TextField
                  name="title"
                  placeholder="Please Specify"
                  value={otherTitle()}
                  onChange={handleChange}
                />
              )}
            </Grid>
          </Grid>
          <Box mt={4} display="flex" justifyContent="center">
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item md={4}>
                <Button
                  variant="outlined"
                  color="default"
                  disabled={isPending}
                  onClick={handlePasswordDialogOpen}
                  onClose={handleDialogClose}
                >
                  Change Password
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isPending}
                >
                  {isPending && counter > 1 ? 'Updating...' : 'Update'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={isPending}
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    reload();
                    history.push('/');
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2} alignItems="flex-start" justify="center">
            <Grid item xs={12} sm={12}>
              {isRejected && error && (
                <Typography color="error" align="center">
                  {error.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={8}>
              {isFulfilled && counter > 1 && (
                <Typography align="center" className={classes.successMessage}>
                  Profile has been updated. Your information will be refreshed
                  at your next login.
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LicensePassword onClick={handleLicensePasswordDialogOpen} />
          </Grid>
        </Grid>
      </Container>
      {modal.password && (
        <ChangePasswordDialog
          onSubmit={handlePasswordChange}
          onClose={handleDialogClose}
        />
      )}
      {modal.licensePassword && (
        <ChangePasswordDialog
          title="Change License Password"
          onSubmit={handleLicensePasswordChange}
          onClose={handleDialogClose}
        />
      )}
    </>
  );
};

export default withRouter(UserProfile);
