import React from 'react';
import { Link } from '@material-ui/core';
import { useAsync } from 'react-async';
import * as productKeysClient from 'utils/productKeysClient';

function getProductKeys() {
  return productKeysClient.productKeys();
}

const LicenseDialog = ({ onClick }) => {
  const { data: productKeys, isLoading } = useAsync({
    promiseFn: getProductKeys,
  });

  let showLicensePasswordChange = false;
  if (!isLoading) {
    showLicensePasswordChange = Boolean(
      productKeys.find(x => x.type === 'AccountsLicense')
    );
  }

  if (!showLicensePasswordChange) {
    return <div />;
  }

  return (
    <Link onClick={onClick} variant="subtitle1" href="#">
      Change license password
    </Link>
  );
};

export default LicenseDialog;
