import queryString from 'query-string';

export const parseUrl = () => {
  const parsed = queryString.parse(window.location.search);

  return {
    ...parsed,
    expiresAt: parsed.expiresAt ? Number(parsed.expiresAt) : Number.MAX_VALUE,
  };
};

const Auth = function(options) {
  return {
    authorize: ({ ssoParams, redirectParams } = {}) => {
      const ssoSearch = {
        RelayState:
          options.redirectUri + '?' + queryString.stringify(redirectParams),
        ...ssoParams,
      };

      window.location =
        options.loginUrl + '&' + queryString.stringify(ssoSearch);
    },
  };
};

export default Auth;
