import React from 'react';
import { Route } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

const RouteWithTitle = ({ title, ...restProps }) => {
  if (title === undefined) throw new Error('RouteWithTitle needs a title prop');

  return (
    <>
      {title && <DocumentTitle title={title + ' • EngageCX Account'} />}
      <Route {...restProps} />
    </>
  );
};

export default RouteWithTitle;
