import React from 'react';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useAsync } from 'react-async';
import * as appsClient from 'utils/appsClient';
import EnvironmentTile from './EnvironmentTile';
import FadeInProgress from 'components/FadeInProgress';

const useStyles = makeStyles(theme => ({
  getStartedLink: {
    textDecoration: 'none',
    paddingLeft: 16,
  },
}));

function getEnvironments() {
  return appsClient.apps();
}

const CloudEnvironments = instances => {
  const classes = useStyles();
  const {
    data: environments = appsClient.initialValue,
    isPending,
    isRejected,
    error,
  } = useAsync({
    promiseFn: getEnvironments,
  });

  const hasInstances = instances.instances && instances.instances.length > 0;
  const hasEnvironments = environments && environments.length > 0;

  if (!hasInstances && !hasEnvironments) return null;

  if (isRejected) {
    return (
      <div>
        <p>There was an error.</p>
        <pre>{error.message}</pre>
      </div>
    );
  }

  return (
    <Grid item>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Box width={20} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" align="center">
                Cloud
              </Typography>
            </Grid>
            <Grid item>
              <Box width={20}>
                {environments && (
                  <FadeInProgress size={20} isPending={isPending} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4} justify="center" alignItems="flex-start">
            {isPending && environments === null && (
              <Box m={4}>
                <FadeInProgress isPending={isPending} />
              </Box>
            )}
            {environments &&
              environments.length !== 0 &&
              environments.map((environment, index) => (
                <Grid item key={index}>
                  <EnvironmentTile
                    label={environment.name}
                    href={environment.url}
                    logo={environment.logo}
                    microservices={environment.microservices}
                  />
                </Grid>
              ))}
            {environments && environments.length === 0 && (
              <Box
                display="flex"
                flexWrap="nowrap"
                justifyContent="center"
                alignItems="center"
                minHeight={96}
              >
                <Grid item>
                  <Typography>No cloud services used.</Typography>
                </Grid>
                <Grid item>
                  <RouterLink
                    to="/get-started"
                    className={classes.getStartedLink}
                  >
                    <Button color="primary" variant="contained">
                      Get Started
                    </Button>
                  </RouterLink>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CloudEnvironments;
