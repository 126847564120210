import React from 'react';
import queryString from 'query-string';
import VerifyResult from './VerifyResult';
import { Switch, Route } from 'react-router-dom';
import CheckEmailVerified from './CheckEmailVerified';
import EmailPendingVerification from './EmailPendingVerification';

const VerifyEmail = props => {
  return (
    <Switch>
      <Route
        path="/verify-email/resend"
        render={props => {
          return <EmailPendingVerification {...props} autoResend />;
        }}
      />
      <Route
        path="/verify-email/result"
        render={props => {
          const query = queryString.parse(props.location.search);
          return (
            <VerifyResult
              {...props}
              email={query['email_verify_success']}
              error={query['email_verify_error']}
            />
          );
        }}
      />
      <Route component={CheckEmailVerified} />
    </Switch>
  );
};

export default VerifyEmail;
