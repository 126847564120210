import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
  MenuItem,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useAsync } from 'react-async';
import * as productKeysClient from 'utils/productKeysClient';
import ActivationDialog from './ActivationDialog';
import fileDownload from 'js-file-download';

const getLicenseKey = ({ licenseData }) => {
  return productKeysClient.getLicenseKey(licenseData);
};

const installLicenseKey = ([licenseKey]) => {
  return productKeysClient.installLicense(licenseKey);
};

const downloadLicense = id => {
  return productKeysClient.downloadProductKey(id);
};

const registerLicense = (id, computerName) => {
  return productKeysClient.registerProductKey(id, computerName);
};

const InstallLicenseDialog = ({ onClose, open, id, handleProductChange }) => {
  const [version, setVersion] = useState(4);
  const [computerName, setComputerName] = useState('');
  const [showActivationDialog, toggleActivationDialog] = useState(false);
  const [keys, setKeys] = useState({
    activationKey: '',
    responseKey: '',
  });

  let licenseData = {
    id: id,
    version,
  };
  const { data: licenseKey, run, error, isPending, counter } = useAsync({
    promiseFn: getLicenseKey,
    licenseData,
    watch: version,
    deferFn: installLicenseKey,
  });

  const handleChange = ({ target: { value } }) => {
    setVersion(value);
  };

  const handleActivationKey = ({ target: { value } }) => {
    setKeys({ ...keys, activationKey: value });
  };

  const installLicense = event => {
    event.preventDefault();
    run({ id, activationKey: keys.activationKey }).then(response => {
      if (!isPending)
        if (typeof response === 'string') {
          toggleActivationDialog(true);
          setKeys({ ...keys, responseKey: response });
          handleProductChange();
        }
    });
  };

  const startDownload = () => {
    downloadLicense(id).then(response => {
      fileDownload(response, 'license.xml');
    });
  };

  const handleComputerId = ({ target: { value } }) => {
    setComputerName(value);
  };

  const handleRegister = event => {
    event.preventDefault();
    registerLicense(id, computerName).then(response => {
      fileDownload(response, `licenseFor${computerName}.xml`);
      handleProductChange();
    });
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open && !showActivationDialog}
        onClose={onClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <form onSubmit={installLicense}>
          <Grid container>
            <Grid item xs>
              <DialogTitle>Install License</DialogTitle>
            </Grid>
            <Grid item>
              <IconButton color="inherit" onClick={onClose} aria-label="Close">
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent>
            <Box mb={2} display="flex" justifyContent="flex-end">
              <TextField value={version} onChange={handleChange} select>
                <MenuItem value={4}>{'License Administrator 4.0'}</MenuItem>
                <MenuItem value={3}>{'License Administrator 3.0'}</MenuItem>
                <MenuItem value={2}>{'License Administrator 2.0'}</MenuItem>
              </TextField>
            </Box>

            {version !== 2 && (
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {
                      'Go to the computer where the license must be installed, open License Administrator and use the key below.'
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{'License key'}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    InputProps={{
                      readOnly: true,
                    }}
                    rows={2}
                    value={licenseKey}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {
                      'When prompted, select Offline Activation. You will receive an activation key. Paste it in the box below.'
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {'Activation key'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    multiline
                    rows={2}
                    onChange={handleActivationKey}
                    value={keys.activationKey}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                </Grid>
              </Grid>
            )}
            {version === 2 && (
              <Grid container justify="flex-start" spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="subtitle2">
                    {'Download License'}
                  </Typography>
                </Grid>
                <Grid item sm={9}>
                  <Typography variant="body1">
                    {
                      'Download the license and use it in the License Administrator to install it'
                    }
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <Button variant="outlined" onClick={startDownload}>
                    Download
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="subtitle2">
                    {'Register Offline'}
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="body1">
                    {
                      'When your server does not have an Internet connection or is located behind a firewall you will not be able to use a regular license. In this case, you must generate an "offline registration ticket" that does not require an Internet connection. Enter the server\'s name as displayed by the License Administrator in the top left corner in the edit box below and press "Download".'
                    }
                  </Typography>
                </Grid>
                <Box p={1}>
                  <form onSubmit={handleRegister}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography variant="body1">
                          {'Computer Name'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          placeholder="Enter Computer Name..."
                          required
                          onChange={handleComputerId}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" type="submit">
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Box width={1} display="flex" justifyContent="flex-end">
              {version !== 2 && (
                <Button color="primary" variant="contained" type="submit">
                  {isPending && counter > 1 ? 'Installing...' : 'Install'}
                </Button>
              )}
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      {keys.responseKey && (
        <ActivationDialog
          open={showActivationDialog}
          onClose={onClose}
          activationKey={keys.responseKey}
        />
      )}
    </>
  );
};

export default InstallLicenseDialog;
