import client from './apiClient';

function signIn(username, password) {
  const result = client('signin', {
    body: { username, password },
  });

  return result;
}

function migrate({ account, passwordResetKey = '' }) {
  const result = client(
    'signin/migrate?passwordResetKey=' + encodeURIComponent(passwordResetKey),
    {
      body: { ...account },
    }
  );

  return result;
}

export { signIn, migrate };
