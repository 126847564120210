import client from './apiClient';
let cache = null;

function cloudInstances() {
  if (cache) return Promise.resolve(cache);

  const result = client('cloudinstances');
  result.then(instance => (cache = instance));

  return result;
}

export { cloudInstances };
