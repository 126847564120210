import React from 'react';
import {
  Dialog,
  IconButton,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useForm from 'utils/useForm';
import { useAsync } from 'react-async';

function validateSignupForm({ newPassword, confirmPassword }) {
  const errors = {};

  const passwordRegexp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])/;

  if (newPassword.length < 6) {
    errors.newPassword = 'Minimum length is 6 characters.';
  } else if (!passwordRegexp.test(newPassword)) {
    errors.newPassword =
      'The password must be a combination of at least 3 of the following: an uppercase letter, a lowercase letter, a digit and a special character!';
  }

  if (newPassword !== confirmPassword)
    errors.confirmPassword = 'Passwords do not match!';

  return errors;
}

const ChangePasswordDialog = ({ onClose, onSubmit, title }) => {
  const { error, isLoading, isSettled, isFulfilled, run, counter } = useAsync({
    deferFn: onSubmit,
  });

  const { values, handleChange, handleSubmit, errors } = useForm(
    updatePassword,
    validateSignupForm
  );

  function updatePassword() {
    run({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    });
  }

  if (isFulfilled) onClose();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>{title || 'Change Password'}</DialogTitle>
      <Box position="absolute" right={0} top={0}>
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography>Old Password</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                name="oldPassword"
                type="password"
                value={values.oldPassword || ''}
                onChange={handleChange}
                fullWidth
                required
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>New Password</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                name="newPassword"
                type="password"
                value={values.newPassword || ''}
                onChange={handleChange}
                fullWidth
                required
                helperText={errors.newPassword}
                error={Boolean(errors.newPassword)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>Confirm Password</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                name="confirmPassword"
                type="password"
                value={values.confirmPassword || ''}
                onChange={handleChange}
                fullWidth
                required
                helperText={errors.confirmPassword}
                error={Boolean(errors.confirmPassword)}
              />
            </Grid>
            {isSettled && error && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {error.message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="default">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            {isLoading && counter > 1 ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePasswordDialog;
