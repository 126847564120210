import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Grid,
  Divider,
  Box,
  Collapse,
} from '@material-ui/core';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import * as accountsClient from 'utils/accountsClient';
import { useAsync } from 'react-async';

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundColor: '#fdec90',
  },
  icon: {
    verticalAlign: 'middle',
    marginTop: 4,
  },
}));

function getAccount() {
  return accountsClient.getAccount(true);
}

const Banner = ({ history }) => {
  const classes = useStyles();
  const [dismissed, setDismissed] = useState(
    new Date(localStorage.getItem('_verify_mail_dissmissed_until_') || null)
  );
  const { data: accountInfo, reload } = useAsync({
    promiseFn: getAccount,
  });

  const handleLaterClick = () => {
    const dissmissedUntil = new Date(new Date().getTime() + 1 * 60 * 60 * 1000);
    localStorage.setItem('_verify_mail_dissmissed_until_', dissmissedUntil);

    setDismissed(dissmissedUntil);
  };

  useEffect(() => {
    const dissmissedUntil = new Date(new Date().getTime() + 1 * 10 * 60 * 1000);
    if (localStorage.getItem('_verify_mail_dissmissed_until_') === null) {
      localStorage.setItem('_verify_mail_dissmissed_until_', dissmissedUntil);
      setDismissed(dissmissedUntil);
    }
  }, []);

  return (
    <div>
      <Collapse
        in={
          accountInfo &&
          accountInfo.expirationDate !== null &&
          dismissed < new Date()
        }
        className={classes.banner}
      >
        <Box m={0.5}>
          <Container maxWidth="md">
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <UnsubscribeIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <Typography>
                  In order to continue to have access to your account, please
                  verify your email in 48 hours after signup.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => handleLaterClick()}
                >
                  LATER
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    reload();
                    history.push('/verify-email/resend', { date: new Date() });
                    handleLaterClick();
                  }}
                >
                  VERIFY NOW
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Divider />
      </Collapse>
    </div>
  );
};

export default withRouter(Banner);
