import React from 'react';
import { useAuth } from 'context/AuthContext';
import RouteWithTitle from 'components/RouteWithTitle';

function AuthRoute(props) {
  const {
    component: Component,
    anonym,
    anonymWithCheckSession,
    render,
    computedMatch,
    ...rest
  } = props;

  const {
    isInitialState,
    isPending,
    isAuthenticated,
    settleForAnonym,
    checkSession,
    signIn,
  } = useAuth();

  return (
    <RouteWithTitle
      {...rest}
      render={() => {
        if (anonym) {
          settleForAnonym();
        } else {
          if (isInitialState) {
            checkSession();
            return <div />;
          }
          if (isPending) return <div />;

          if (anonymWithCheckSession) {
            settleForAnonym();
          } else if (!isAuthenticated()) {
            signIn({ redirectUrl: window.location.href });
            return <div />;
          }
        }

        return render ? (
          render()
        ) : (
          <Component location={rest.location} computedMatch={computedMatch} />
        );
      }}
    />
  );
}

export default AuthRoute;
