import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Link,
} from '@material-ui/core';
import { useAuth } from 'context/AuthContext';
import * as accountsClient from 'utils/accountsClient';
import { useAsync } from 'react-async';
import { Redirect } from 'react-router-dom';
import useForm from 'utils/useForm';
import queryString from 'query-string';
import SignupSuccess from './Success';

function createAccount([account]) {
  return accountsClient.createAccount(account);
}

function validateSignupForm({ email, password, confirmPassword }) {
  const errors = {};

  const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!emailRegexp.test(email))
    errors.email = 'Please enter a valid email address!';

  const passwordRegexp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/)])/;

  if (password.length < 6) {
    errors.password = 'Minimum length is 6 characters.';
  } else if (!passwordRegexp.test(password)) {
    errors.password =
      'The password must be a combination of at least 3 of the following: an uppercase letter, a lowercase letter, a digit and a special character!';
  }

  if (password !== confirmPassword)
    errors.confirmPassword = 'Passwords does not match!';

  return errors;
}

const Signup = props => {
  const { signIn, isAuthenticated } = useAuth();

  const { values, handleChange, handleSubmit, errors, fillValues } = useForm(
    handleSignup,
    validateSignupForm
  );

  const { error, isLoading, isFulfilled, run, isPending } = useAsync({
    deferFn: createAccount,
  });

  const query = queryString.parse(props.location.search);

  useEffect(() => {
    if (query['email']) fillValues({ email: query['email'] });
    //eslint-disable-next-line
  }, []);

  function handleSignIn(e) {
    e.preventDefault();

    if (query['redirectUrl']) window.location = query['redirectUrl'];
    else signIn();
  }

  function handleSignup() {
    run(values);
  }

  if (isAuthenticated()) {
    if (query['redirectUrl']) window.location = query['redirectUrl'];

    return query['redirectUrl'] ? null : <Redirect to="/" />;
  }

  if (isFulfilled) return <SignupSuccess email={values.email} />;

  return (
    <Container maxWidth="sm">
      <Box mt={8} mb={4}>
        <Typography variant="h6" align="center">
          Sign-Up
        </Typography>
      </Box>

      <Box mt={4} mb={4}>
        <Typography variant="subtitle1" align="center">
          Create your free EngageCX Account
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="flex-start" justify="center">
          <Grid item xs={12} sm={3}>
            <Typography>First Name</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="firstName"
              value={values.firstName || ''}
              onChange={handleChange}
              fullWidth
              required
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Last Name</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="lastName"
              value={values.lastName || ''}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="email"
              value={values.email || ''}
              onChange={handleChange}
              fullWidth
              helperText={errors.email}
              error={Boolean(errors.email)}
              disabled={query.email ? true : false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Password</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="password"
              value={values.password || ''}
              onChange={handleChange}
              fullWidth
              type="password"
              helperText={errors.password}
              error={Boolean(errors.password)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Confirm Password</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="confirmPassword"
              value={values.confirmPassword || ''}
              onChange={handleChange}
              fullWidth
              type="password"
              helperText={errors.confirmPassword}
              error={Boolean(errors.confirmPassword)}
              required
            />
          </Grid>
        </Grid>
        <Box mt={4}>
          <Grid container alignItems="center" direction="column">
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <Typography>
                    By creating an Ecrion account I confirm that I have read and
                    agree to the Ecrion Customer Terms of Service.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isLoading ? 'Signing-Up...' : 'Sign-Up'}
                  </Button>
                </Grid>
                {!isPending && error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error.message}</Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography>-or-</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link href="#" onClick={handleSignIn} variant="body1">
                    Sign-In with your existing account
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Container>
  );
};

export default Signup;
