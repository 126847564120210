import client from './apiClient';
let cache = null;
const LOCAL_STORAGE_KEY = '_environments_cache_';

function environments() {
  if (cache) return Promise.resolve(cache);

  const result = client('environments');

  result.then(environments => {
    cache = environments;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(environments));
  });

  return result;
}

// environments request takes some time to complete
// use local storage to cache the retrieved items
const initialValue = (function() {
  const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY);
  return localStorageValue ? JSON.parse(localStorageValue) : null;
})();

function createEnvironment(environment) {
  const result = client('environments', { body: environment });
  return result;
}

export { environments, initialValue, createEnvironment };
