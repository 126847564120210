import React from 'react';
import { Tabs, Tab, Box, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import CloudGroup from './CloudGroup';
import DownloadsGroup from './DownloadsGroup';
import Support from 'components/Support';
import ProductKeys from 'components/ProductKeys';
import * as cloudInstancesClient from 'utils/cloudInstancesClient';
import * as activateAccountClient from 'utils/activateAccountClient';
import { useAsync } from 'react-async';
import { useAuth } from 'context/AuthContext';

const useStyles = makeStyles(theme => ({
  tabRoot: {
    margin: '0px 24px',
    '@media (min-width: 960px)': {
      fontSize: 14,
    },
  },
}));

function getCloudInstances() {
  return cloudInstancesClient.cloudInstances();
}

const resendActivationEmail = ([email]) => {
  return activateAccountClient.sendActivation(email);
};

const Home = props => {
  const { tab = 'benefits', history } = props;
  const {
    data: { profile },
  } = useAuth();

  const { data: instances, isSettled } = useAsync({
    promiseFn: getCloudInstances,
    deferFn: resendActivationEmail,
  });

  const classes = useStyles();

  function handleChange(event, newValue) {
    history.push('/home/' + newValue);
  }

  if (
    Date.parse(new Date(`${profile.expirationDate}`)) <
    Date.parse(new Date().toUTCString())
  ) {
    history.push('/verify-email');
  }

  return (
    <Container maxWidth="lg">
      <Box mt={2} mb={6}>
        <Tabs value={tab} onChange={handleChange} centered>
          <Tab className={classes.tabRoot} value="benefits" label="Benefits" />
          <Tab
            className={classes.tabRoot}
            value="product-keys"
            label="Product Keys"
          />
          <Tab className={classes.tabRoot} value="support" label="Support" />
        </Tabs>
      </Box>
      {tab === 'benefits' && (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={6}
          direction="column"
          wrap="nowrap"
        >
          {isSettled && <CloudGroup instances={instances} />}
          <Grid item>
            <DownloadsGroup />
          </Grid>
        </Grid>
      )}
      {tab === 'product-keys' && <ProductKeys />}
      {tab === 'support' && <Support />}
    </Container>
  );
};

export default withRouter(Home);
