import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Avatar,
  FormControl,
  FormHelperText,
  ButtonBase,
} from '@material-ui/core';
import { useAsync } from 'react-async';
import * as environmentsClient from 'utils/environmentsClient';
import CloudInstanceSelect from './CloudInstanceSelect';
import fileUtils from 'utils/fileUtils';
import queryString from 'query-string';
import useForm from 'utils/useForm';
import { makeStyles } from '@material-ui/styles';
import BusinessIcon from '@material-ui/icons/Business';
import * as appsClient from 'utils/appsClient';

const useStyles = makeStyles(theme => ({
  logoSize: {
    width: '64px',
    height: '64px',
  },
  logoMargins: {
    marginTop: '20px',
  },
}));

function createEnvironment([environment]) {
  return environmentsClient.createEnvironment(environment);
}

function startAppInstall(url, environmentName, appName) {
  return appsClient.installApp(url, environmentName, appName);
}

const CreateOrganization = props => {
  const { values, handleChange, handleSubmit, errors } = useForm(
    handleCreateOrganization,
    validateOrganizationForm
  );

  const [logoSizeError, setLogoSizeError] = useState(false);
  const [installingApp, setInstallingApp] = useState(false);

  function validateLogoSize(logoFile) {
    if (logoFile && logoFile.size > 100000) return false;

    return true;
  }

  function validateOrganizationForm({ name, logoFile }) {
    const errors = {};

    if (name.length < 3)
      errors.name =
        'The environment name must be of minimum 3 characters length';

    const organizationRegexp = /^[^*#%/|\\<>+?:"]+$/;
    if (!organizationRegexp.test(name))
      errors.name =
        'The environment name can\'t contain any of the following characters: \\  #  % / : + ? " < > |';

    return errors;
  }

  function handleLogoChange(e) {
    handleChange(e);
    setLogoSizeError(!validateLogoSize(e.target.files[0]));
  }

  async function handleCreateOrganization() {
    if (logoSizeError) return;

    let logoBase64 = '';
    if (values.logoFile)
      logoBase64 = await fileUtils.getBase64(values.logoFile);

    run({ name: values.name, instanceId: values.instanceId, logo: logoBase64 });
  }

  const { isPending, run, error, counter, setError } = useAsync({
    deferFn: createEnvironment,
    onResolve: environment => {
      const query = queryString.parse(props.location.search);
      if (query['install-app']) {
        startAppInstall(
          environment.url,
          environment.name,
          query['install-app']
        ).then(() => (window.location.href = '/'));
        setInstallingApp(true);
      } else window.location.href = '/';
    },
  });

  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Box mt={8} mb={4}>
        <Typography variant="h6" align="center">
          Get Started with Ecrion Cloud
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant="subtitle1" align="center">
          Setup your organization in the Ecrion Cloud
        </Typography>
      </Box>
      <form
        onSubmit={e => {
          setError(null);
          handleSubmit(e);
        }}
      >
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={4}>
            <Typography>Organization Name</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              required
              name="name"
              value={values.name || ''}
              onChange={handleChange}
              autoFocus
              helperText={errors.name}
              error={Boolean(errors.name)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CloudInstanceSelect
              name="instanceId"
              value={values.instanceId || ''}
              onChange={handleChange}
            />
          </Grid>
          <React.Fragment>
            <Grid item xs={12} sm={12} className={classes.logoMargins}>
              <Grid container justify="center">
                <Grid item>
                  <FormControl>
                    <Grid
                      container
                      spacing={1}
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          name="logoFile"
                          onChange={handleLogoChange}
                          style={{ display: 'none' }}
                        />
                        <label
                          htmlFor="contained-button-file"
                          className={classes.logoMaxSize}
                        >
                          <ButtonBase component="span">
                            {!values.logoFile && (
                              <Avatar
                                alt="Company Logo"
                                className={classes.logoSize}
                              >
                                <BusinessIcon fontSize="large" />
                              </Avatar>
                            )}
                            {values.logoFile && (
                              <img
                                src={URL.createObjectURL(values.logoFile)}
                                alt={'Environment Icon'}
                                className={classes.logoSize}
                              />
                            )}
                          </ButtonBase>
                        </label>
                      </Grid>
                      <FormHelperText error={logoSizeError}>
                        {logoSizeError
                          ? 'File size should be less than 100KB'
                          : 'Max 100KB (64 x 64 pixels recommended)'}
                      </FormHelperText>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        </Grid>
        <Box mt={3}>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!values.instanceId || isPending || installingApp}
              >
                {(isPending && counter > 1) || installingApp
                  ? 'Creating Organization...'
                  : 'Create Organization'}
              </Button>
            </Grid>
            {!isPending && error && (
              <Grid item>
                <Typography color="error" align="center">
                  {error.message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </form>
    </Container>
  );
};

export default CreateOrganization;
