import { useState, useEffect } from 'react';

const useForm = (callback, validate = () => ({}), initialValues = {}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).every(key => !errors[key]) && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
    trimValues();
  };

  const trimValues = () => {
    var trimmedValues = {};
    for (var key in values) {
      trimmedValues[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
    }

    setValues(trimmedValues);
  };

  const handleChange = event => {
    event.persist && event.persist();

    setValues(values => ({
      ...values,
      [event.target.name]:
        event.target.type === 'file'
          ? event.target.files[0]
          : event.target.value,
    }));
  };

  const fillValues = data => {
    setValues(values => ({
      ...values,
      ...data,
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    fillValues,
  };
};

export default useForm;
