import client from './apiClient';

function changePassword({ oldPassword, newPassword }) {
  const updatePassword = {
    oldPassword,
    newPassword,
  };

  return client(`password`, { body: updatePassword, method: 'PUT' });
}

function changeLicensePassword({ oldPassword, newPassword }) {
  const updatePassword = {
    oldPassword,
    newPassword,
  };

  return client(`password/license`, {
    body: updatePassword,
    method: 'PUT',
  });
}

export { changePassword, changeLicensePassword };
