import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Select,
  OutlinedInput,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import * as supportClient from 'utils/supportClient';
import { useAsync } from 'react-async';
import AddIcon from '@material-ui/icons/Add';
import { useUser } from 'context/UserContext';
import useForm from 'utils/useForm';
import Close from '@material-ui/icons/Close';

function createTicket([ticket]) {
  return supportClient.createTicket(ticket);
}

const Support = () => {
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    validateSupportForm
  );

  function submit() {
    var formData = new FormData();

    formData.append('subject', values.subject);
    formData.append('email', values.email);
    formData.append('description', values.description);
    if (values.priority) formData.append('priority', values.priority);
    else formData.append('priority', 'normal');

    if (values.attachment0) formData.append('attachment0', values.attachment0);
    if (values.attachment1) formData.append('attachment1', values.attachment1);
    if (values.attachment2) formData.append('attachment2', values.attachment2);
    run(formData);
  }

  function deleteAttachment(index) {
    for (let currentIndex = index; currentIndex < 3; currentIndex++) {
      handleChange({
        target: {
          name: 'attachment' + currentIndex,
          value: values['attachment' + (currentIndex + 1)],
        },
      });
    }
  }

  function validateSupportForm({ email }) {
    const errors = {};

    const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegexp.test(email))
      errors.email = 'Please enter a valid email address!';

    var attachmentSize = !values.attachment0 ? 0 : values.attachment0.size;
    attachmentSize += !values.attachment1 ? 0 : values.attachment1.size;
    attachmentSize += !values.attachment2 ? 0 : values.attachment2.size;

    if (attachmentSize > 4000000)
      errors.files = 'The total attachments size should be less than 4Mb';

    return errors;
  }

  const { email } = useUser();

  const { isLoading, run, isFulfilled, error, isSettled, counter } = useAsync({
    deferFn: createTicket,
  });

  const attachmentsCount = [
    values.attachment0,
    values.attachment1,
    values.attachment2,
  ].filter(val => Boolean(val)).length;

  if (!values.email) values.email = email;

  if (isFulfilled && counter > 1)
    return (
      <Container maxWidth="sm">
        <Box mt={2} mb={4}>
          <Typography variant="subtitle2" align="center">
            Your request has been successfully created. Our technical support
            team will contact you shortly.
          </Typography>
        </Box>
      </Container>
    );

  return (
    <Container maxWidth="sm">
      <Box mt={2} mb={4}>
        <Typography variant="subtitle2" align="center">
          Request technical support or general product support
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="flex-start" justify="center">
          <Grid item xs={12} sm={3}>
            <Typography>Subject</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="subject"
              value={values.subject || ''}
              onChange={handleChange}
              fullWidth
              required
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              fullWidth
              required
              name="email"
              value={values.email || ''}
              onChange={handleChange}
              helperText={errors.email}
              error={Boolean(errors.email)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Priority</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Select
              input={<OutlinedInput />}
              fullWidth
              required
              name="priority"
              value={values.priority || 'normal'}
              onChange={handleChange}
            >
              <MenuItem value="normal">Normal</MenuItem>
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="high">High</MenuItem>
              <MenuItem value="highest">Highest</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Enter your comments in the box below. If your comment is related
              to an error you encountered, include the following information:
            </Typography>
            <ul>
              <Typography component="li">
                The steps needed to reproduce the problem (the more detail, the
                better).
              </Typography>
              <Typography component="li">
                The exact text of any error messages you received.
              </Typography>
              <Typography component="li">
                Attach the input files you used in testing, including all
                external images. All information you provide is kept in strict
                confidentiality and deleted when is no longer needed.
              </Typography>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              fullWidth
              required
              multiline
              rows="5"
              name="description"
              value={values.description || ''}
              onChange={handleChange}
            />
          </Grid>
          {[values.attachment0, values.attachment1, values.attachment2].map(
            (file, index) =>
              file && (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={3}>
                    <Typography>Attachment {index + 1}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Grid container spacing={1} alignItems="flex-start">
                      <Grid item xs>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          value={file.name}
                        />
                      </Grid>
                      <Grid item>
                        <input
                          id={'browse-button' + index}
                          type="file"
                          name={'attachment' + index}
                          onChange={handleChange}
                          style={{ display: 'none' }}
                        />
                        <label htmlFor={'browse-button' + index}>
                                        <Button variant="outlined" component="span" size="small">
                            Browse
                          </Button>
                        </label>
                        <IconButton
                          id={'delete-button' + index}
                          variant="outlined"
                          component="span"
                          style={{
                            position: 'absolute',
                            marginLeft: '5px',
                            marginTop: '-6px',
                          }}
                          color="inherit"
                          aria-label="Close"
                          onClick={() => {
                            deleteAttachment(index);
                          }}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
          )}

          <Grid item xs={12} sm={12}>
            <Grid
              container
              spacing={1}
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <input
                  id="contained-button-file"
                  type="file"
                  name={'attachment' + attachmentsCount}
                  disabled={attachmentsCount === 3}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="contained-button-file">
                  <Button component="span" disabled={attachmentsCount === 3}>
                    <AddIcon style={{ color: 'green' }} />
                    Add attachment
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading && counter > 1 ? 'Submitting...' : 'Submit'}
            </Button>
          </Grid>
          {Boolean(errors.files) && (
            <Grid item xs={12}>
              <Typography color="error" align="center">
                {errors.files}
              </Typography>
            </Grid>
          )}
          {isSettled && error && (
            <Grid item xs={12}>
              <Typography color="error" align="center">
                {error.message}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default Support;
