import React from 'react';
import { makeStyles } from '@material-ui/styles';
import FadeInProgress from '../FadeInProgress';
const useStyles = makeStyles(theme => ({
  fullHeight: {
    position: 'absolute',
    top: '45%',
    left: 0,
    right: 0,
  },
}));

const PageProgress = () => {
  const classes = useStyles();

  return (
    <div className={classes.fullHeight}>
      <FadeInProgress isPending />
    </div>
  );
};

export default PageProgress;
