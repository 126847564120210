import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useAsync } from 'react-async';
import * as productKeysClient from 'utils/productKeysClient';

const restoreProductKeysGUID = ([restoreKey]) => {
  return productKeysClient.restoreProductKeys(restoreKey);
};

const RestoreDialog = ({ onClose }) => {
  const [keys, setKeys] = useState({
    restoreKey: '',
    responseKey: '',
  });

  const { run, error, isPending, counter } = useAsync({
    deferFn: restoreProductKeysGUID,
  });

  const handleRestoreKey = ({ target: { value } }) => {
    setKeys({ ...keys, restoreKey: value });
  };

  const restoreProductKeys = event => {
    event.preventDefault();
    run({ restoreKey: keys.restoreKey }).then(response => {
      if (!isPending)
        if (typeof response === 'string') {
          setKeys({ ...keys, responseKey: response });
        }
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open
      onClose={onClose}
      disableRestoreFocus
      disableBackdropClick
    >
      {!keys.responseKey && (
        <form onSubmit={restoreProductKeys}>
          <Grid container>
            <Grid item xs>
              <DialogTitle>Restore HA Product Keys</DialogTitle>
            </Grid>
            <Grid item>
              <IconButton color="inherit" onClick={onClose} aria-label="Close">
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">{'Paste below the key generated by the Licensing Server tool in order to restore the product keys in your HA deployment.'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleRestoreKey}
                  value={keys.restoreKey}
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box width={1} display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" type="submit">
                {isPending && counter > 1 ? 'Restoring...' : 'Restore'}
              </Button>
            </Box>
          </DialogActions>
        </form>
      )}
      {keys.responseKey && (
        <>
          <Grid container>
            <Grid item xs>
              <DialogTitle>Restore Product Key</DialogTitle>
            </Grid>
            <Grid item>
              <IconButton color="inherit" onClick={onClose} aria-label="Close">
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {'Paste the restore key in the text field below'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  multiline
                  rows={4}
                  value={keys.responseKey}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box width={1} display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" onClick={onClose}>
                Close
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default RestoreDialog;
