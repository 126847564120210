import React from 'react';
import { Box, Typography } from '@material-ui/core';

const separator = {
  height: '60px',
};

const Footer = () => {
  return (
    <React.Fragment>
      <Box style={separator} />
      <Box
        position="absolute"
        width={1}
        bottom={0}
        mt={2}
        mb={2}
        display="flex"
        justifyContent="center"
      >
        <Typography variant="body2" color="textSecondary" align="center">
          Copyright 2023 &copy; MHC Automation / All Rights Reserved
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
