import React, { useEffect, useState } from 'react';
import { Typography, Link } from '@material-ui/core';

const RedirectCounter = ({ handleSignIn }) => {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    let timer = 5;
    let intervalID = null;
    intervalID = setInterval(() => {
      setSeconds(timer--);
      if (timer < 0) {
        clearInterval(intervalID);
        handleSignIn();
      }
    }, 1000);
    //eslint-disable-next-line
  }, []);

  return (
    <Typography variant="subtitle1" align="center">
      {`You will be redirected to  your account in ${seconds} seconds. If you're not redirected, please click `}
      <Link href="#" onClick={handleSignIn}>
        <span>{'here.'}</span>
      </Link>
    </Typography>
  );
};

export default RedirectCounter;
