import React, { useState } from 'react';
import {
  Typography,
  Container,
  TextField,
  Grid,
  Box,
  Button,
  Link,
} from '@material-ui/core';
import useForm from 'utils/useForm';
import * as signInClient from 'utils/signIn';
import { useAsync } from 'react-async';
import MigrateLogin from './MigrateLogin';
import LoginMigrated from './LoginMigrated';

function signIntoAccount([account]) {
  return signInClient.signIn(account.username, account.password);
}

const SignIn = () => {
  const { values, handleChange, handleSubmit } = useForm(handleSignIn);
  const [account, setAccount] = useState();

  const { error, isLoading, run, isPending } = useAsync({
    deferFn: signIntoAccount,
    onResolve: setAccount,
  });

  function handleSignIn() {
    run(values);
  }

  if (account && account.isMigratedToSSO)
    return <LoginMigrated email={account.email} />;

  if (account) return <MigrateLogin account={account} />;

  return (
    <Container maxWidth="sm">
      <Box mt={8} mb={4}>
        <Typography variant="h6" align="center" noWrap>
          Sign-In
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="flex-start" justify="center">
          <Grid item xs={12} sm={3}>
            <Typography>Username</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="username"
              value={values.username || ''}
              onChange={handleChange}
              fullWidth
              required
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>Password</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="password"
              value={values.password || ''}
              onChange={handleChange}
              fullWidth
              type="password"
              required
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Link href="/password-recovery" variant="body1">
            Forgot password?
          </Link>
        </Box>
        <Box mt={2}>
          <Grid container alignItems="center" direction="column">
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isLoading ? 'Signing-In...' : 'Sign-In'}
                  </Button>
                </Grid>
                {!isPending && error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error.message}</Typography>
                  </Grid>
                )}
                {!isPending && !error && account === null && (
                  <Grid item xs={12}>
                    <Typography color="error">
                      {
                        'Could not find account, please check username and password.'
                      }
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Container>
  );
};

export default SignIn;
