import React from 'react';
import CreateOrganization from './CreateOrganization';
import * as environmentsClient from 'utils/environmentsClient';
import * as appsClient from 'utils/appsClient';
import { useAsync } from 'react-async';
import queryString from 'query-string';
import PageProgress from 'components/PageProgress';
import { Typography, Box, Container, Button, Grid } from '@material-ui/core';
import { withRouter } from 'react-router';

function getEnvironments() {
  return environmentsClient.environments();
}

function startAppInstall(url, environmentName, appName) {
  return appsClient.installApp(url, environmentName, appName);
}

const GetStarted = props => {
  const { location, history } = props;
  const { data, isPending, error, setError } = useAsync({
    promiseFn: getEnvironments,
    onResolve: environments => {
      if (environments && environments.length > 0) {
        const query = queryString.parse(location.search);
        startAppInstall(
          environments[0].url,
          environments[0].name,
          query['install-app']
        )
          .then(() => (window.location.href = '/'))
          .catch(error => setError(error));
      }
    },
  });

  if (isPending) return <PageProgress />;

  if (error)
    return (
      <Container maxWidth="md">
        <Box mt={8} mb={4}>
          <Typography variant="h6" align="center">
            {'App install error'}
          </Typography>
        </Box>
        <Box mt={4} mb={4}>
          <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <Typography variant="subtitle1" align="center" color="error">
                {error.message}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push('/')}
              >
                {'Go to home page'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );

  if (!data || data.length === 0) return <CreateOrganization {...props} />;

  return null;
};

export default withRouter(GetStarted);
