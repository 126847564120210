import client from './apiClient';
let cache = null;

function productKeys() {
  if (cache) return Promise.resolve(cache);

  const result = client('backendproductkeys');
  result.then(environment => (cache = environment));

  return result;
}

function updateProductKey(id) {
  return client(`backendproductkeys/update/${id}`);
}

function getLicenseKey({ id, version = '' }) {
  return client(`backendproductkeys/${id}?version=${version}`);
}

function installLicense({ id, type = 'AccountsLicense', activationKey }) {
  return client(`backendproductkeys/install/${id}?type=${type}`, {
    body: activationKey,
  });
}

function restoreProductKeys({ restoreKey }) {
  return client(`backendproductkeys/updateGuids`, {
    body: restoreKey,
  });
}

function uninstallLicense({ id, type, uninstallKey }) {
  return client(`backendproductkeys/uninstall/${id}?type=${type}`, {
    body: uninstallKey,
  });
}

function getActivationKey(id) {
  return client(`backendproductkeys/activationkey/${id}`);
}

function downloadProductKey(id) {
  return client(`backendproductkeys/download/${id}`);
}

function registerProductKey(id, computerName) {
  return client(`backendproductkeys/register/${id}`, {
    body: computerName,
  });
}

export {
  productKeys,
  updateProductKey,
  getLicenseKey,
  installLicense,
  uninstallLicense,
  getActivationKey,
  downloadProductKey,
  registerProductKey,
  restoreProductKeys,
};
