import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from 'components/Home';
import TypographicScale from 'components/TypographicScale';
import UserProfile from 'components/UserProfile';
import AuthCallback from 'components/AuthCallback';
import AuthRoute from 'components/AuthRoute';
import Signup from 'components/Signup';
import PasswordRecovery from 'components/PasswordRecovery';
import GetStarted from 'components/GetStarted';
import CreateOrganization from 'components/GetStarted/CreateOrganization';
import PageNotFound from 'components/PageNotFound';
import VerifyEmail from 'components/VerifyEmail';
import { useAuth } from 'context/AuthContext';
import Banner from 'components/Banner';
import SignIn from 'components/SignIn';
import ErrorBoundary from './ErrorBoundary';

function App() {
  const { isSettled, isAuthenticated } = useAuth();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Router>
          <ErrorBoundary>
            {isSettled && <Navbar />}
            {isAuthenticated() && <Banner />}
            <Switch>
              <AuthRoute path="/" exact component={Home} title="Home" />
              <AuthRoute path="/home/" exact component={Home} title="Home" />
              <AuthRoute
                path="/home/benefits"
                component={Home}
                title="Benefits"
              />
              <AuthRoute
                path="/profile/"
                component={UserProfile}
                title="User Profile"
              />
              <AuthRoute
                path="/home/product-keys"
                render={() => <Home tab="product-keys" />}
                title="Product Keys"
              />
              <AuthRoute
                path="/home/support"
                render={() => <Home tab="support" />}
                title="Support"
              />
              <AuthRoute
                anonym
                path="/signin"
                exact
                component={SignIn}
                title="Sign-In"
              />
              <AuthRoute
                anonymWithCheckSession
                path="/signup/"
                exact
                component={Signup}
                title="Signup"
              />
              <AuthRoute
                anonym
                path="/password-recovery"
                component={PasswordRecovery}
                title="Password Recovery"
              />

              <Route path="/auth-callback" component={AuthCallback} />

              <AuthRoute
                path="/get-started/"
                component={GetStarted}
                title="Create Organization"
              />

              <AuthRoute
                path="/create-organization/"
                component={CreateOrganization}
                title="Create Organization"
              />

              <AuthRoute
                anonymWithCheckSession
                path="/verify-email/"
                component={VerifyEmail}
                title="Verify Email"
              />

              {process.env.NODE_ENV === 'development' && (
                <AuthRoute
                  anonym
                  path="/typo/"
                  component={TypographicScale}
                  title=""
                />
              )}
              <AuthRoute
                anonymWithCheckSession
                component={PageNotFound}
                title="Page not found"
              />
            </Switch>
          </ErrorBoundary>
        </Router>
        <Footer />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
