import React from 'react';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useUser } from 'context/UserContext';
import { useAuth } from 'context/AuthContext';

const useStyles = makeStyles(theme => ({
  userButton: {
    padding: theme.spacing(1),
  },
  buttonActive: {
    backgroundColor: grey[600],
  },
  profileAnchor: {
    textDecoration: 'none',
    color: 'inherit',
  },
  menuList: {
    width: 150,
  },
}));

const User = ({ history }) => {
  const classes = useStyles();
  const { signOut } = useAuth();
  const { firstName, lastName } = useUser();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleProfileClick() {
    history.push('/profile');
    handleClose();
  }

  return (
    <Box maxWidth={{ xs: 100, md: 200 }}>
      <Button
        onClick={handleClick}
        className={[classes.userButton, anchorEl && classes.buttonActive].join(
          ' '
        )}
        color="inherit"
      >
        {`${firstName} ${lastName}`}
      </Button>
      {anchorEl && (
        <Menu
          MenuListProps={{ className: classes.menuList }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
          <MenuItem onClick={signOut}>Logout</MenuItem>
        </Menu>
      )}
    </Box>
  );
};

export default withRouter(User);
