import React from 'react';
import { useAuth } from 'context/AuthContext';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { removeHost } from 'utils/urlUtils';

const AuthCallback = props => {
  const { handleAuthentication } = useAuth();

  handleAuthentication();

  const query = queryString.parse(props.location.search);
  var path = query.redirectUrl ? removeHost(query.redirectUrl) : '/';
  return <Redirect to={path} />;
};

export default AuthCallback;
