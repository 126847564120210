import React from 'react';
import * as accountsClient from 'utils/accountsClient';
import { useAsync } from 'react-async';
import PageProgress from 'components/PageProgress';
import EmailPendingVerification from './EmailPendingVerification';
import { useUser } from 'context/UserContext';
import queryString from 'query-string';
import VerifyResult from './VerifyResult';

function getAccount() {
  return accountsClient.getAccount(true);
}

const CheckEmailVerified = props => {
  const { isPending, data: accountInfo } = useAsync({
    promiseFn: getAccount,
    watch: props.location.state,
  });

  const query = queryString.parse(props.location.search);

  const user = useUser();

  if (isPending) return <PageProgress />;

  if (!user || !accountInfo || accountInfo.expirationDate) {
    return (
      <EmailPendingVerification
        {...props}
        email={user ? user.email : query.email}
      />
    );
  }

  return <VerifyResult {...props} />;
};

export default CheckEmailVerified;
